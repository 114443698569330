import React from 'react'
import styled from 'styled-components'

const NavItemWrapper = styled.div`
  position: relative;
  display: flex;
  & > a {
    position: relative;
    padding: 10px;
    text-decoration: none;
    color: var(--color-text);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    letter-spacing: 1px;
  }
`

const NavItem: React.FC = ({ children }) => (
  <NavItemWrapper>{children}</NavItemWrapper>
)

export default NavItem
