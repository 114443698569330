import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Navigation from '../../components/Navigation/Navigation'
import MaxWidthWrapper from '../../components/MaxWidthWrapper/MaxWidthWrapper'

const HeaderWrapper = styled.header`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
`

const HeaderLeft = styled.div`
  flex: 1 1 0%;
  display: flex;
  align-items: baseline;
  gap: 32px;
`

const HeaderRightDesktop = styled.div`
  display: none;
  @media ${p => p.theme.QUERIES.tabletsAndBigger} {
    display: flex;
  }
`

const HeaderRightMobile = styled.div`
  @media ${p => p.theme.QUERIES.tabletsAndBigger} {
    display: none;
  }
`

const Header: React.FC = () => (
  <MaxWidthWrapper>
    <HeaderWrapper>
      <HeaderLeft>
        <Link to="/">{'Programistafrontend'}</Link>
        <Navigation />
      </HeaderLeft>
      <HeaderRightDesktop>{'desktop'}</HeaderRightDesktop>
      <HeaderRightMobile>{'mobile'}</HeaderRightMobile>
    </HeaderWrapper>
  </MaxWidthWrapper>
)

export default Header
