import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import NavItem from './NavItem'

const NavigationWrapper = styled.nav`
  display: flex;
`
const NavigationList = styled.ul`
  display: flex;
  list-style: none;
`
const NavigationListItem = styled.li`
  margin: 8px;
`

const Navigation: React.FC = () => (
  <NavigationWrapper>
    <NavigationList>
      <NavigationListItem>
        <NavItem>
          <Link to="/">{'Home'}</Link>
        </NavItem>
      </NavigationListItem>
      <NavigationListItem>
        <NavItem>
          <Link to="/about">{'O blogu'}</Link>
        </NavItem>
      </NavigationListItem>
    </NavigationList>
  </NavigationWrapper>
)

export default Navigation
